@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');


body {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.cm_home_page {
  background: #F5F5F5 !important;
}

h5 {
  font-size: 14px;
  font-weight: 300;
  opacity: 0.76;
}

.other_page {
  margin-top:102px;
}


.btn {
  border-radius: 40px;
}

.btn-primary {
  background-color: #2786B7;
    border-color: #2786B7;
    padding: 10px 20px;
}

.btn-secondary {
  background-color: #C7B138;
  border-color: #C7B138;
}

.section {
  padding: 100px 0;
}

.light-bg {
  background: #F5F5F5;
}



.cm_width {
  width: 250px;
  height: 250px;
  object-fit: cover;
}

header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  padding: 25px 0;
  background: transparent;
  transition: all 0.28s;
}

.cm_home_page header,
.scroll-header header {
  background: #004183;
  padding: 10px 0;
}

.cm_logo {
  vertical-align: middle;
  display: inline-block;
  max-width: 240px;
  width: 100%;
}

.cm_logo img {
  width: 100%;
}

.cm_menu {
  display: inline-block;
  width: calc(100% - 240px);
  text-align: right;
}

.cm_menu > ul,
.cm_menu > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cm_menu > ul > li {
  display: inline-block;
  position: relative;
}

.cm_menu > ul > li > a {
  display: block;
  font-weight: 500;
  padding: 10px 20px;
  color: #fff;
}

.cm_auth_link > a {
  font-size: 90%;
  color: #A0C638 !important;
}

.cm_menu > ul > li > ul {
  position: absolute;
  background: #fff;
  width: 130px;
  text-align: left;
  padding: 15px 0 !important;
  border-radius: 15px;
  display: none;
}

.cm_menu > ul > li:hover > ul {
  display: block;
}

.cm_menu > ul > li > ul:before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  background: #fff;
  transform: rotate(45deg);
  top: -6px;
  left: 25px;
}

.cm_menu > ul > li > ul li a {
  display: block;
  color: #5F5F5F;
  font-weight: 500;
  padding: 5px 10px;
}







.section.main_slider {
  position: relative;
}

.main_slider .carousel {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
}

.main_slider .carousel .slide:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.62);
  left: 0;
  top: 0;
}

.main_slider  .carousel .slide > div {
  height: 100vh;
  min-height: 630px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.main_slider .control-dots {
  width: auto !important;
  bottom: 40px !important;
  right: 15px;
  margin: 0 !important;
  padding: 0 !important;
}

.main_slider .carousel .control-dots .dot {
  display: block;
  background: transparent;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  margin-top: 15px;
  opacity: 1;
}

.main_slider .carousel .control-dots .dot.selected, .main_slider .carousel .control-dots .dot:hover {
  background: #fff;
}

.slider_content {
  position: relative;
  min-height: 630px;
  width: calc(100% - 100px);
  margin: 0 auto;
  height: 100vh;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.slider_content ul {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 875px;
  width: 100%;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
}

.contact_content ul li,
.slider_content ul li {
  display: inline-block;
  width: calc(100% / 4 - 1px);
  vertical-align: middle;
  padding: 20px 10px;
  color: #fff;
}

.contact_content img,
.slider_content img {
  width: 50px;
  margin-bottom: 12px;
}

.contact_content p,
.slider_content p {
  margin: 0;
}

.color_bg_1 {
  background: rgb(0,0,192);
  background: -moz-linear-gradient(left, rgba(0,0,192,1) 0%, rgba(0,33,66,.8) 100%);
  background: -webkit-linear-gradient(left, rgba(0,0,192,1) 0%,rgba(0,33,66,.8) 100%);
  background: linear-gradient(to right, rgba(0,0,192,1) 0%,rgba(0,33,66,.8) 100%);
}

.color_bg_2 {
  background: rgb(40,135,185);
  background: -moz-linear-gradient(left, rgba(40,135,185,1) 0%, rgba(20,68,93,.8) 100%);
  background: -webkit-linear-gradient(left, rgba(40,135,185,1) 0%,rgba(20,68,93,.8) 100%);
  background: linear-gradient(to right, rgba(40,135,185,1) 0%,rgba(20,68,93,.8) 100%);
}

.color_bg_3 {
  background: rgb(161,199,56);
  background: -moz-linear-gradient(left, rgba(161,199,56,1) 0%, rgba(100,89,28,.8) 100%);
  background: -webkit-linear-gradient(left, rgba(161,199,56,1) 0%,rgba(100,89,28,.8) 100%);
  background: linear-gradient(to right, rgba(161,199,56,1) 0%,rgba(100,89,28,.8) 100%);
}

.color_bg_4 {
  background: rgb(128,99,3);
  background: -moz-linear-gradient(left, rgba(128,99,3,1) 0%, rgba(128,99,3,.8) 100%);
  background: -webkit-linear-gradient(left, rgba(128,99,3,1) 0%,rgba(128,99,3,.8) 100%);
  background: linear-gradient(to right, rgba(128,99,3,1) 0%,rgba(128,99,3,.8) 100%);
}

.section_img {
  max-width: 85%;
}

.donated_main_content {
  color: #fff;
  background: #004183;
  padding: 25px 20px !important;
}

.donated_main_content p {
  font-size: 15px;
  opacity: 0.76;
}

.total_donated_bg {
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
}

.cm_card_img {
  position: relative;
}

.cm_card_img .serach_icon {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(2.5);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
}

.cm_card_img .serach_icon img {
  width: 100%;
}

.cm_card_content {
  padding: 20px;
  color: #fff;
}

.cm_card_content h4 {
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 20px;
}

.donated {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 2px;
}

.donated_line {
  position: absolute;
  height: 2px;
  /* background: #fff; */
  width: 23%;
  left: 0;
  bottom: -2px;
}

.donated h5 span {
  position: absolute;
  right: 0;
  top: 0;
}

.row .cm_card:nth-child(1n) .cm_card_content {
  background: #C7B138;
}
.row .cm_card:nth-child(2n) .cm_card_content {
  background: #2786B7;
}
.row .cm_card:nth-child(3n) .cm_card_content {
  background: #9FC337;
}
.row .cm_card:nth-child(4n) .cm_card_content {
  background: #FBC105;
}

.cm_card_img:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  transform: scale(0);
  transition: all 0.25s;
}

.row .cm_card:nth-child(1n) .cm_card_img:after {
  background: rgba(199, 177, 56, .8);
}
.row .cm_card:nth-child(2n) .cm_card_img:after {
  background: rgba(39, 134, 183, .8);
}
.row .cm_card:nth-child(3n) .cm_card_img:after {
  background: rgba(159, 195, 55, .8);
}
.row .cm_card:nth-child(4n) .cm_card_img:after {
  background: rgba(251, 193, 5, .8);
}

.cm_card:hover .cm_card_img:after {
  transform: scale(1);
}

.cm_card:hover .cm_card_img .serach_icon {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
}

.cm_card:hover h5 {
  opacity: 1;
}

.testimunial_wrapper {
  padding-top: 0px;
}

.our_team_section {
  background: #EBEBEB;
  padding: 100px;
  border-radius: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.our_team_section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.our_team_section ul li {
  display: inline-block;
}

.our_team_section ul li a img {
  display: block;
  max-height: 22px;
}
.our_team_section ul li a:hover{
  opacity: 0.2;
}

.our_team_section ul li a {
  padding: 8px;
  display: block;
}

.person-card {
  text-align: center;
}

.person-card img {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.testimonial_wrapper {
  position: relative;
  background: #2786B7;
}

.testimonial_bg_img {
  width: 50%;
  margin-left: 50%;
}

.testimonial_bg_img img {
  width: 100%;
}

.testimonial_wrapper .container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  margin: 0 auto;
}


.testimonial_wrapper .carousel.carousel-slider {
  padding: 50px;
}
.testimonial_wrapper .carousel .slide {
  background: transparent;
  color: #fff;
}

p.carousel-status {
  display: none !important;
}

.testimonial_wrapper .carousel .control-dots {
  position: static !important;
}

.impact_wrapper {
  background: #004183;
  background: -moz-linear-gradient(left, #004183 0%, #2887b9 100%);
  background: -webkit-linear-gradient(left, #004183 0%,#2887b9 100%);
  background: linear-gradient(to right, #004183 0%,#2887b9 100%);
  padding: 100px 0;
  color: #FBC105;
  text-align: center;
}

.impact_wrapper h3 {
  margin-bottom: 50px;
  font-size: 20px;
  color: #fff;
}

.impact_wrapper img {
  max-height: 60px;
  margin-bottom: 20px;
}

.ReactGridGallery_tile {
  width: calc(100% / 4 - 15px);
  margin: 7px !important;
  float: none !important;
  display: inline-block;
}

.ReactGridGallery_tile img {
  width: 100% !important;
  height: auto !important;
  margin: 0 !important;
}

.ReactGridGallery_tile-viewport {
  height: auto !important;
  width: 100% !important;
}

div#lightboxBackdrop {
  z-index: 99999;
}

footer {
  background: #004183;
  color: #fff;
  font-size: 14px;
  padding: 35px 0;
  
}

footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}

footer ul li {
  display: inline-block;
  margin-left: 15px;
}

footer ul li:first-child {
  margin-left: 0 !important;
}

footer ul li a {
  background-size: 300px !important;
  background-repeat: no-repeat !important;
}

footer ul li a:hover {
  opacity: 0.5;
}

a.icon_facbook {
  background-position: 8px 2px;
}

a.icon_twiter {
  background-position: -42px 2px;
}

a.icon_youtube {
  background-position: -73px 4px;
  background-size: 235px !important;
}

a.icon_insta {
  background-position: -134px 4px;
  background-size: 255px !important;
}

a.icon_googleplus {
  background-position: -183px 4px;
  background-size: 255px !important;
}

a.icon_share {
  background-position: -231px 4px;
  background-size: 255px !important;
}

footer p {
  margin: 0;
}

.image_gallary_wrapper {
  padding-bottom: 30px;
}

.section_header {
  background: #2786B7;
  color: #fff;
  padding: 50px 0;
  margin-bottom: 30px;

}

.join_us_area {
  min-height: 260px !important;
}

.section_header h2 {
  margin: 0;
  font-size: 30px;
}

.section_header a {
  color: #fff;
}

.form-control {
  border-radius: 25px;
  border-color: #707070;
  font-weight: 300;
  min-height: 50px;
  margin-top: 10px;
  
}
select{
  -moz-appearance: none;
    -webkit-appearance: none;
    
}

.cm_home_page .form-control {
  background: #F5F5F5 !important;
}
.photo-uploaded{
  width: 50px;
  height: 50px;
  border: 1px solid #888;
  margin: 10px 10px 10px 0;
}
/* -----------------------------------css for payment-------------------------------------- */
.dashboard-box {
  box-shadow: 0px 0px 23px #dcdcdc70;
  border-radius: 12px;
  background: #fff;
  padding: 40px;
}
.payment{
  min-height: calc(100vh - 254px);
}
.graycolorh4 {
  color: #616161;
  padding-bottom: 8px;
}
.payment-table {
  position: relative;
}
.payment-table tr:first-child , .payment-table tr:nth-child(3) {
  border-bottom: 1px solid #eeeeee9e;
}
.payment-table tr td{
  padding: 25px;
}
.payment-table tr td:nth-child(1){
  width: 15%;
  /* border-bottom:1px solid #eee */
}
.payment-table tr td:nth-child(2){
  width: 55%;
  font-size: 24px;
}
.payment-table tr td:nth-child(3){
  width: 20%;
}
.gray_tick {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 30px;
}
.gray_tick.active svg path {
  fill: #004183;
}
.card-number{
  color: #004183;
  font-weight: 700;
}
.remove_btn {
  color: #000;
  font-size: 13px;
}
.hi{
  min-height: calc(100vh - 106px);
}
.cardtext{
  font-size: 16px
}
.addressparent{
  position: relative;
  border-right:1px solid #8888881f;
  text-align: center;
}
.address{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50% , -50%);
  transform: translate(-50% , -50%);
  width: 100%;
  padding: 0 60px;
  font-size: 19px;
  color: #000;
}
.btn-danger {
  color: #fff;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-info{
  color: #000;
  background-color: rgb(255, 255, 255) !important;
  border-color: #fff !important;
}
#myBtn {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #0909a3;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  background-color: #555;
}

.cm_menu .active {
  border-bottom: 1px solid #fff;
}
.cm_menu ul li a {
  text-decoration: none;
}
.CircularProgressbar-path{
  position: relative;
}
.CircularProgressbar-text{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-10% , 4%);
}
@media(min-width: 992px) {
  .contact_us_form_wrapper form {
    max-width: 850px;
    margin: 0 auto;
  }
}

form .btn.btn-primary {
  min-width: 165px;
  min-height: 50px;
  
}

input#captcha {
  margin: 0;
}

.contact_content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact_content ul li {
  text-align: center;
  color: #fff;
}

.gallery_banner {
  padding: 8px;
  padding-top: 70px;
}

.gallery_banner img {
  width: 100%;
}

.cm_space {
  margin-top: 20px;
}

.cm_radio_wrapper {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  margin-right: 15px;
}

.cm_radio_box {
  position: absolute;
  width: 18px;
  height: 18px;
  border: 1px solid #707070;
  left: 0;
  top: 3px;
  border-radius: 100%;
}

.cm_radio_input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 999;
  opacity: 0;
}

.cm_radio_box:after {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #707070;
  content: "";
  border-radius: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
}

.cm_radio_input:checked + .cm_radio_box:after {
  opacity: 1;
  visibility: visible;
}

.cm_checkbox_wrapper {
  position: relative;
  display: inline-block;
  padding-left: 50px;
}

.cm_checkbox_box {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 1px solid #707070;
  position: absolute;
  left: 0;
  top: 0;
}

span.cm_checkbox_box:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 16px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  top: 1px;
  left: 6px;
  opacity: 0;
  visibility: hidden;
}

.cm_checkbox_input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
}

.cm_checkbox_input:checked + .cm_checkbox_box {
  background: #707070;
}

.cm_checkbox_input:checked + .cm_checkbox_box:after {
  opacity: 1;
  visibility: visible;
}

.cm_file_upload_wrapper {
  position: relative;
  padding-top: 40px;
  text-align: right;
}

.upload_file_btn {
  display: inline-block;
  background: #E0E0E0;
  padding: 10px 25px;
  line-height: 30px;
  border-radius: 40px;
  border: 1px solid #707070;
  position: relative;
  min-width: 180px;
  text-align: center;
}

.cm_upload_input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 9;
  opacity: 0;
}

span.file_upload_img {
  position: absolute;
  width: 110px;
  height: 110px;
  background: #fff;
  left: 15px;
  top: 10px;
  border: 1px solid #707070;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

span.login_link {
  display: inline-block;
  margin-left: 30px;
  text-align: center;
  font-size: 14px;
  position: relative;
  top:8px;
}

span.login_link a {
  display: block;
  font-size: 12px;
  color: #2786B7;
  font-weight:600;
}

.cm_upload_text {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  background: #EFEFEF;
  text-align: center;
  font-size: 12px;
  padding: 10px;
  line-height: 20px;
}

.cm_btn_group {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}

.cm_btn_group a {
  display: block;
  margin-bottom: 12px;
  line-height: 35px;
}

.cm_btn_group a:last-child {
  margin: 0;
}

.my_rofile_page p {
  margin-top: 16px;
  margin-bottom: 35px;
}

.my_rofile_page label {
  font-weight: 500;
}

.project_img {
  min-height: 500px;
  margin-top: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.project_img_box > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #2786B7;
  color: #fff;
  margin-bottom: 50px;
}

.project_img_box > ul > li {
  display: inline-block;
  border-right: 1px solid #fff;
  padding: 30px 15px;
  text-align: center;
  vertical-align: middle;
}

.project_img_box > ul > li:nth-child(1){width: 24%;}
.project_img_box > ul > li:nth-child(2){width: 17%;}
.project_img_box > ul > li:nth-child(3){width: 35%;text-align: left;}
.project_img_box > ul > li:nth-child(4){width: 24%;border-right: 0px;}

.project_img_box > ul > li h4 {
  font-size: 16px;
  margin-bottom: 10px;
}

.project_content {
  color: #707070;
}

.project_content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 50px;
}

.project_content ul li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10%;
  font-size: 14px;
}
.project_content ul li:last-child {
  margin-right: 0px;
}

.project_content h3 {
  font-size: 18px;
  margin: 40px 0;
  color: #707070;
}

.share_project_icon a img {
  max-height: 55px;
}

.share_project_icon a img {
  max-height: 55px;
}

.ReactGridGallery_tile-icon-bar {
  display: none !important;
}

.member_list {
  padding: 0;
  list-style: none;
  width: 84%;
  margin: 0 auto;
}

.member_list li {
  display: inline-block;
  width: calc(100% / 7);
  padding: 10px;
  font-size: 13px;
  text-align: center;
  margin-bottom: 20px;
}

.member_list li p {
  margin: 0;
  margin-top: 10px;
  display:block;
  list-style: none;
}

.member_list li img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 100%;
}

.cm_table_heading {
  background: #004183;
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px 25px 0 0;
}

.donators_list table {
  font-size: 14px;
}

.donators_list td,
.donators_list th {
  border: 1px solid #707070;
}


@media(min-width: 992px){
  .mobile_triger {
    display: none !important;
  }
}


@media(max-width: 1199px){
  .cm_width {
    width: 185px;
    height: 185px;
  }
}


@media(max-width: 1023px){
  .c100.big {
    font-size: 180px !important;
  }
}

@media(max-width: 991px){
  .header_wrapper .container {
    position: relative;
}

.member_list li {
  width: calc(100% / 5);
}

.mobile_triger {
  width: 40px;
  position: absolute;
  top: 50%;
  right: 15px;
  z-index: 999;
  transform: translateY(-50%);
  cursor: pointer;
}
  .cm_menu {
    position: fixed;
    width: 300px;
    background: #004183;
    right: -310px;
    top: 0;
    height: 100%;
    text-align: left;
    padding-top: 110px;
    box-shadow: -5px 0px 10px 0px rgba(0,0,0,0.2);
    transition: all 0.3s;
}

.header_wrapper.open .cm_menu {
  right: 0;
}

.cm_menu > ul > li {
  display: block;
  margin-bottom: 15px;
}

.cm_menu > ul > li > ul {
  display: block !important;
  position: relative;
  width: calc(100% - 30px);
  border-radius: 0;
  margin: 15px;
}




.project_img_box > ul > li {
  border-right: 1px solid #86bbd7;
  width: 50% !important;
  border-top: 1px solid #86bbd7;
  min-height: 140px;
}








}



@media(max-width: 767px){
  .slider_content ul {
    display: none !important
  }
  .sml-img{
    display: none;
  }
  .addressparent{
    margin-bottom: 100px;
  }
  .btn-secondary {
    margin-bottom: 30px;
  }

  .c100 {
    display: block !important;
    margin: 0 auto !important;
}

.testimonial_wrapper .container {
  position: static;
  transform: translate(0, 0);
  margin: 0 auto;
}

.testimonial_bg_img {
  width: 100%;
  margin-left: 0;
}

.testimonial_wrapper .carousel.carousel-slider {
  padding: 50px 15px;
}

.our_team_section {
  padding: 50px 25px;
}

.section_header,
.section_header .text-right {
  text-align: center!important;
}

.section_header h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.member_list li {
  width: calc(100% / 3);
}

}
.input_error_msg {
  position: absolute;
  right: 20px;
  bottom: -20px;
  font-size: 13px;
  color: #dc3545;


}
.pb-2{
  padding-bottom: 2rem!important
}
.gray_text{
  color: #004183;
    font-weight: 300;
}
.white_text{
  color: #FFF;
  font-weight: 300;
}
.form-group {
  position: relative;
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  display: block;
}
.success_wrapper{
  margin-top:102px;
}

img.success_logo_img {
  max-width: 400px;
  width: 100%;
}

.share_project_icon a {
  margin-right: 6%;
}

@media(max-width: 1199px) and (min-width: 992px) {
  .upload_file_btn {
    display: inline-block;
    background: #E0E0E0;
    padding: 4px 8px;
    line-height: 30px;
    border-radius: 40px;
    border: 1px solid #707070;
    position: relative;
    min-width: 119px;
    text-align: center;
    font-size: 13px;
}
}
@media (min-width:768px){
  .fix-footer{
    height: calc(100vh - 208px);
  }
}
.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background: rgba(0,0,0,0.5);
  }
  .lds-roller {
  display: inline-block;
  width: 64px;
  position: absolute;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  }
  .lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
  }
  .lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #004183;
  margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
  }
  .lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
  }
  .lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
  }
  .lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
  }
  .lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
  }
  .lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
  }
  .lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
  }
  .lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
  }
  @keyframes lds-roller {
  0% {
  transform: rotate(0deg);
  }
  100% {
  transform: rotate(360deg);
  }
  }
  .dropzone {
    width : 100%;
    height : 20%;
    border : 1px solid black;
  }
  .slider_text{
    font-weight:400
  }
  .imgSize{
    width:20px;
    height:20px
  }
  .markerSize{
    width:20px;
    height:40px
  }
  .blockimg {
    align-self: center!important;
    display: flex;
  
}
.blockimg img{
margin-right: 13px;
}
.blockimg img:last-child {
  margin-right: 13px;
  margin: 14px 0px;
}
.img-fluid {
  width: 100%;
  
}
.cm_min_h{
  height: 200px;
  object-fit: cover;
}
img.response_img {
  width: 225px;
  height: 150px;
  object-fit: cover;
}

@media (max-width:991px){
 
  img.response_img {
    width: 250px;
    height: 150px;
    display: block;
    margin: 0 auto !important;
    margin-top: 20px !important;
    
}
}
@media (max-width:767px){
 
  img.response_img {
    width: 300px;
    height: 200px;
    
}
}

/ ---------------------------------image modal popup------------------------------- /
.popup-content.image_modal-content {
  background: transparent !important;
  box-shadow: none;
  border: none !important;
  width: 34% !important;
}
.image-gallery-fullscreen-button::before, .image-gallery-play-button::before, .image-gallery-left-nav::before, .image-gallery-right-nav::before {
  color: transparent;
  line-height: 0;
  text-shadow: none;
  transition: none;
}
.image-gallery-fullscreen-button::before, .image-gallery-play-button::before {
  font-size: 0;
  padding: 0;
  text-shadow: none;
}
.image-gallery-thumbnails {
  margin-top: 14px;
}
.image-gallery-slide {
  background: transparent;
}
.image_modal-overlay button.close {
  color: #fff;
  font-size: 54px;
  font-weight: 100;
  position: absolute;
  top: 22px;
  left: -31px;
}
.image-gallery-thumbnail img {
  border-radius: 6px;
  box-shadow: 0px 0px 8px #000;
}
.image-gallery-thumbnail.active {
  border: none;
}
.image-gallery-thumbnail.active img {
  box-shadow: none !important;
}
.image-gallery-thumbnails-container{
  overflow-y: auto;
}
